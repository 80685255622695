(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/store/assets/reducers/team-reducer.js') >= 0) return;  svs.modules.push('/components/marketplace-data/store/assets/reducers/team-reducer.js');
"use strict";

const _excluded = ["games"],
  _excluded2 = ["isUpdatingFollow"],
  _excluded3 = ["isUpdatingFollow"];
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (-1 !== e.indexOf(n)) continue; t[n] = r[n]; } return t; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  MEMBER_LEAVE_TEAM_SUCCESS,
  FOLLOW_GROUP_INIT,
  FOLLOW_GROUP_SUCCESS,
  FOLLOW_GROUP_FAILED
} = svs.components.marketplaceData.members.actions;
const {
  INVITE_LINK_FETCH_SUCCESS,
  INVITE_LINK_GENERATE_SUCCESS
} = svs.components.marketplaceData.inviteMembers.actions;
const {
  TEAM_CREATE_SUCCESS
} = svs.components.marketplaceData.createTeam.actions;
const {
  FRACTION_SUBSCRIPTION_SUCCESS
} = svs.components.marketplaceData.fractionSubscription.actions;
const {
  END_TEAM_SUCCESS,
  FETCH_TEAM_INIT,
  FETCH_TEAM_FAILED,
  FETCH_TEAM_FINISHED,
  FETCH_NEXT_RETAILERS_AND_TEAMS_INIT,
  FETCH_NEXT_RETAILERS_AND_TEAMS_FAILED,
  FETCH_NEXT_RETAILERS_AND_TEAMS_SUCCESS
} = svs.components.marketplaceData.teams.actions;
const {
  EDIT_RETAILER_EDIT_SUCCESS
} = svs.components.marketplaceRetailAdminData.editRetailer.actions;
const {
  parseEditorState
} = svs.components.marketplaceRetailAdmin.richText.helpers;
const {
  payFraction
} = svs.components.marketplaceData.fractionPayment.actions;
const {
  groupTypeNames
} = svs.components.marketplaceData.constants;
const initialState = {
  error: null,
  data: [],
  loading: false,
  hasFetched: false,
  removedTeams: []
};
function changeGroup(state, groupId, callback) {
  return _objectSpread(_objectSpread({}, state), {}, {
    data: state.data.map(group => {
      if (groupId !== group.id) {
        return group;
      }
      return callback(group);
    })
  });
}
const reducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case FETCH_TEAM_INIT:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: null,
        loading: true
      });
    case FETCH_TEAM_FAILED:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error,
        loading: false,
        hasFetched: true
      });
    case FETCH_TEAM_FINISHED:
      {
        const hasTeam = state.data.filter(team => team.id === action.team.id).length === 1;
        let data;
        if (hasTeam) {
          data = state.data.map(team => team.id === action.team.id ? _objectSpread(_objectSpread({}, team), action.team) : team);
        } else {
          data = [...state.data, action.team];
        }
        data = data.map(d => d.type === 'RETAILER' || isJson(d.description) ? _objectSpread(_objectSpread({}, d), {}, {
          richDescription: parseEditorState(d.description)
        }) : d);
        return _objectSpread(_objectSpread({}, state), {}, {
          data,
          loading: false,
          hasFetched: true
        });
      }
    case INVITE_LINK_FETCH_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        data: state.data.map(team => {
          if (team.id !== action.groupId) {
            return team;
          }
          return _objectSpread(_objectSpread({}, team), {}, {
            token: action.token,
            tokenExpires: action.expires
          });
        })
      });
    case INVITE_LINK_GENERATE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        data: state.data.map(team => {
          if (team.id !== action.groupId) {
            return team;
          }
          return _objectSpread(_objectSpread({}, team), {}, {
            token: action.token,
            tokenExpires: action.expires
          });
        })
      });
    case FETCH_NEXT_RETAILERS_AND_TEAMS_INIT:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: null,
        loading: true
      });
    case FETCH_NEXT_RETAILERS_AND_TEAMS_FAILED:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error,
        loading: false,
        hasFetched: true
      });
    case FETCH_NEXT_RETAILERS_AND_TEAMS_SUCCESS:
      {
        const teamsWithoutGames = action.teams.map(team => {
          const {
              games
            } = team,
            noGames = _objectWithoutProperties(team, _excluded);
          return noGames;
        });
        const hasTeam = state.data && state.data.filter(team => teamsWithoutGames.some(teamWithoutGame => team.id === teamWithoutGame.id)).length > 0;
        let data;
        if (hasTeam) {
          const existingTeams = state.data ? state.data.filter(team => teamsWithoutGames.some(teamWithoutGame => team.id === teamWithoutGame.id)) : [];
          const nonExistingTeams = teamsWithoutGames.filter(teamWithoutGame => !existingTeams.some(team => team.id === teamWithoutGame.id));
          data = state.data.map(team => {
            const existingTeam = existingTeams.find(teamWithoutGame => team.id === teamWithoutGame.id);
            return existingTeam ? _objectSpread(_objectSpread({}, team), {}, {
              existingTeam
            }) : team;
          });
          data = data.concat([...nonExistingTeams]).sort((a, b) => a.name.localeCompare(b.name));
        } else {
          data = state.data ? [...state.data, ...teamsWithoutGames] : [...teamsWithoutGames];
        }
        return _objectSpread(_objectSpread(_objectSpread({}, state), {}, {
          data,
          loading: false,
          hasFetched: true
        }, action.groupTypeFilter === groupTypeNames.PRIVATE && {
          customerTeamsCount: action.count,
          customerTeamsOffset: state.customerTeamsOffset ? state.customerTeamsOffset + action.offset : action.offset,
          customerTeamsTotalHits: action.totalHits
        }), action.groupTypeFilter === groupTypeNames.RETAILER && {
          retailerTeamsCount: action.count,
          retailerTeamsOffset: state.retailerTeamsOffset ? state.retailerTeamsOffset + action.offset : action.offset,
          retailerTeamsTotalHits: action.totalHits
        });
      }
    case TEAM_CREATE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        data: [...state.data, action.team],
        hasFetched: true
      });
    case FRACTION_SUBSCRIPTION_SUCCESS:
      return changeGroup(state, action.groupId, group => _objectSpread(_objectSpread({}, group), {}, {
        fractionSubscription: action.newAmount
      }));
    case END_TEAM_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        data: state.data.filter(team => team.id !== action.groupId),
        removedTeams: [...state.removedTeams, Number(action.groupId)]
      });
    case MEMBER_LEAVE_TEAM_SUCCESS:
      if (action.isParticipatingInGame) {
        return state;
      }
      return _objectSpread(_objectSpread({}, state), {}, {
        data: state.data.filter(team => team.id !== action.groupId)
      });
    case FOLLOW_GROUP_INIT:
      return changeGroup(state, action.groupId, team => _objectSpread(_objectSpread({}, team), {}, {
        isUpdatingFollow: true
      }));
    case FOLLOW_GROUP_SUCCESS:
      return changeGroup(state, action.groupId, team => {
        const {
            isUpdatingFollow
          } = team,
          rest = _objectWithoutProperties(team, _excluded2);
        return _objectSpread(_objectSpread({}, rest), {}, {
          isFollowing: action.follow
        });
      });
    case payFraction.fulfilled:
      return changeGroup(state, action.groupId, team => _objectSpread(_objectSpread({}, team), {}, {
        isFollowing: true
      }));
    case FOLLOW_GROUP_FAILED:
      return changeGroup(state, action.groupId, team => {
        const {
            isUpdatingFollow
          } = team,
          rest = _objectWithoutProperties(team, _excluded3);
        return _objectSpread(_objectSpread({}, rest), {}, {
          isUpdatingFollowError: action.error
        });
      });
    case EDIT_RETAILER_EDIT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        data: state.data.map(team => {
          if (team.id === action.groupId) {
            return _objectSpread(_objectSpread({}, team), typeof action.isPublished !== 'undefined' ? {
              isPublished: action.isPublished
            } : {});
          }
          return team;
        })
      });
    default:
      return state;
  }
};
function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
setGlobal('svs.components.marketplaceData.store.reducers.teamReducer', reducer);

 })(window);