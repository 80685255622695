(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/store/assets/reducers/draws-reducer.js') >= 0) return;  svs.modules.push('/components/marketplace-data/store/assets/reducers/draws-reducer.js');
"use strict";

const _excluded = ["drawEvents"],
  _excluded2 = ["drawEvents"],
  _excluded3 = ["drawEvents"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (-1 !== e.indexOf(n)) continue; t[n] = r[n]; } return t; }

const {
  products
} = svs.utils;
const {
  combineReducers
} = RTK;
const {
  FETCH_PRODUCTS_DRAWS,
  FETCH_PRODUCTS_DRAWS_FAILURE,
  FETCH_PRODUCTS_DRAWS_SUCCESS,
  FETCH_ALL_DRAWS_SUCCESS,
  FETCH_ALL_DRAWS_FAILURE,
  FETCH_ALL_DRAWS,
  FETCH_SINGLE_DRAW,
  FETCH_SINGLE_DRAW_FAILURE,
  FETCH_SINGLE_DRAW_SUCCESS,
  FETCH_SINGLE_FORECAST_SUCCESS,
  FETCH_SPORTKRYSS,
  FETCH_SPORTKRYSS_FAILURE,
  FETCH_SPORTKRYSS_SUCCESS,
  ADD_FORECAST,
  FETCH_NET_SALES_SUCCESS
} = svs.components.marketplaceData.draws.actions;
const drawsReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case FETCH_SINGLE_DRAW_SUCCESS:
      {
        const drawsToAdd = action.draws.map(_ref => {
          let {
              drawEvents
            } = _ref,
            drawWithoutDrawEvents = _objectWithoutProperties(_ref, _excluded);
          return drawWithoutDrawEvents;
        });
        const drawsToAddDrawNumbers = action.draws.map(draw => draw.drawNumber);
        return _objectSpread(_objectSpread({}, state), {}, {
          [action.productName]: (state[action.productName] || []).filter(currentDraw => drawsToAddDrawNumbers.indexOf(currentDraw.drawNumber) === -1).concat(drawsToAdd)
        });
      }
    case FETCH_PRODUCTS_DRAWS_SUCCESS:
      {
        const drawsWithoutDrawEvents = action.draws.map(draw => {
          const {
              drawEvents
            } = draw,
            drawWithoutDrawEvents = _objectWithoutProperties(draw, _excluded2);
          return drawWithoutDrawEvents;
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          [action.productName]: (state[action.productName] || []).filter(oldDraw => !action.draws.find(newDraw => newDraw.drawNumber === oldDraw.drawNumber)).concat(drawsWithoutDrawEvents)
        });
      }
    case FETCH_ALL_DRAWS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true
      });
    case FETCH_ALL_DRAWS_SUCCESS:
      {
        const hasReplacement = (existingDraw, prodName) => action.productDraws[prodName].draws.find(newDraw => newDraw.drawNumber === existingDraw.drawNumber);
        const productDrawsWithoutDrawEvents = {};
        const keys = Object.keys(action.productDraws);
        for (let i = 0; i < keys.length; ++i) {
          const key = keys[i];
          productDrawsWithoutDrawEvents[key] = action.productDraws[key].draws.map(draw => {
            const {
                drawEvents
              } = draw,
              drawWithoutDrawEvents = _objectWithoutProperties(draw, _excluded3);
            return drawWithoutDrawEvents;
          });
        }
        return _objectSpread(_objectSpread(_objectSpread({}, state), action.products.reduce((mem, prodName) => _objectSpread(_objectSpread({}, mem), {}, {
          [prodName]: (state[prodName] || []).filter(d => !hasReplacement(d, prodName)).concat(productDrawsWithoutDrawEvents[prodName])
        }), {})), {}, {
          loading: false,
          lastFetch: new Date().toISOString()
        });
      }
    case FETCH_ALL_DRAWS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        drawsError: 'Ett fel skedde när vi försökte hämta spelomgångar, försök igen senare.',
        loading: false
      });
    case FETCH_SPORTKRYSS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true
      });
    case FETCH_SPORTKRYSS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        sportKryss: action.response,
        loading: false
      });
    case FETCH_SPORTKRYSS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        drawsError: 'Ett fel skedde när vi försökte hämta Sportkryss, försök igen senare.',
        loading: false
      });
    case FETCH_NET_SALES_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        [action.productName]: (state[action.productName] || []).map(draw => {
          if (draw.drawNumber !== action.drawNumber) {
            return draw;
          }
          return _objectSpread(_objectSpread({}, draw), {}, {
            currentNetSale: action.netSale
          });
        })
      });
    default:
      return state;
  }
};
const drawEventsReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case FETCH_SINGLE_DRAW_SUCCESS:
      if (products.helpers.isRacing(action.productName)) {
        return state;
      }
      return _objectSpread(_objectSpread({}, state), {}, {
        [action.productName]: _objectSpread(_objectSpread({}, state[action.productName]), {}, {
          [action.drawNumber]: action.draws[0].drawEvents
        })
      });
    case FETCH_PRODUCTS_DRAWS_SUCCESS:
      {
        if (products.helpers.isRacing(action.productName)) {
          return state;
        }
        return _objectSpread(_objectSpread({}, state), {}, {
          [action.productName]: action.draws.reduce((events, draw) => _objectSpread(_objectSpread(_objectSpread({}, events), state[action.productName]), {}, {
            [draw.drawNumber]: draw.drawEvents
          }), {})
        });
      }
    default:
      return state;
  }
};
const drawLoadingReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case FETCH_PRODUCTS_DRAWS:
      return _objectSpread(_objectSpread({}, state), {}, {
        [action.productName]: _objectSpread(_objectSpread({}, state[action.productName]), {}, {
          loading: true
        })
      });
    case FETCH_PRODUCTS_DRAWS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        [action.productName]: _objectSpread(_objectSpread({}, state[action.productName]), {}, {
          loading: false
        })
      });
    case FETCH_PRODUCTS_DRAWS_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          [action.productName]: _objectSpread(_objectSpread({}, state[action.productName]), {}, {
            loading: false
          })
        });
      }
    case FETCH_ALL_DRAWS_SUCCESS:
      {
        return Object.keys(action.productDraws).reduce((mem, prodName) => _objectSpread(_objectSpread({}, mem), {}, {
          [prodName]: _objectSpread({}, mem[prodName])
        }), state);
      }
    default:
      return state;
  }
};
function forecastReducer() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ADD_FORECAST:
    case FETCH_SINGLE_FORECAST_SUCCESS:
      return [...state.filter(forecast => forecast.productId !== action.productId && forecast.drawNumber !== action.drawNumber), _objectSpread(_objectSpread({}, action.forecast), {}, {
        productId: action.productId
      })];
    default:
      return state;
  }
}
function loadingSingleReducer() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case FETCH_SINGLE_DRAW:
      return true;
    case FETCH_SINGLE_DRAW_FAILURE:
    case FETCH_SINGLE_DRAW_SUCCESS:
      return false;
    case FETCH_PRODUCTS_DRAWS_SUCCESS:
      {
        if (products.helpers.isRacing(action.productName)) {
          return false;
        }
        return state;
      }
    default:
      return state;
  }
}
function drawEventsErrorMessageReducer() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case FETCH_PRODUCTS_DRAWS_FAILURE:
      return action.errorMessage;
    case FETCH_PRODUCTS_DRAWS:
      return '';
    default:
      return state;
  }
}
setGlobal('svs.components.marketplaceData.store.reducers.drawsReducer', combineReducers({
  loadingSingle: loadingSingleReducer,
  draws: drawsReducer,
  drawEvents: drawEventsReducer,
  drawEventsErrorMessage: drawEventsErrorMessageReducer,
  drawLoading: drawLoadingReducer,
  forecast: forecastReducer
}));

 })(window);