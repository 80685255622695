(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/store/assets/reducers/shares-reducer.js') >= 0) return;  svs.modules.push('/components/marketplace-data/store/assets/reducers/shares-reducer.js');
"use strict";

const _excluded = ["currentShares"];
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (-1 !== e.indexOf(n)) continue; t[n] = r[n]; } return t; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  FETCH_SHARES_INIT,
  FETCH_SHARES_FAILED,
  FETCH_SHARES_SUCCESS
} = svs.components.marketplaceData.shares.actions;
const initialState = {
  error: null,
  offset: 0,
  totalHits: 0,
  currentShares: [],
  loading: false,
  hasFetched: false
};
const reducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case FETCH_SHARES_INIT:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: null,
        loading: true
      });
    case FETCH_SHARES_FAILED:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error,
        loading: false,
        hasFetched: true
      });
    case FETCH_SHARES_SUCCESS:
      {
        const _action$data = action.data,
          {
            currentShares: newShares
          } = _action$data,
          restData = _objectWithoutProperties(_action$data, _excluded);
        const existingShares = state.currentShares;

        const uniqueShares = [...existingShares, ...newShares].reduce((acc, share) => {
          const existingIndex = acc.findIndex(existingShare => existingShare.containerId === share.containerId);
          if (existingIndex !== -1) {
            acc[existingIndex] = _objectSpread(_objectSpread({}, acc[existingIndex]), share);
          } else {
            acc.push(share);
          }
          return acc;
        }, []);
        return _objectSpread(_objectSpread(_objectSpread({}, state), restData), {}, {
          currentShares: uniqueShares,
          loading: false,
          hasFetched: true
        });
      }
    default:
      return state;
  }
};
setGlobal('svs.components.marketplaceData.store.reducers.sharesReducer', reducer);

 })(window);